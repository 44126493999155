import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { tradersApi } from 'api';
import {
  CloseFormikDialogResult,
  DataWrapper,
  Dialog,
  DialogProps,
  FormControls,
  FormikNumericField,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { requisitesLimits } from 'types';
import { validationUtils } from 'utils';

type Props = DialogProps;

type Values = Pick<
  requisitesLimits,
  'payinMaxOrderAmount' | 'payinMinOrderAmount'
>;

export const RequisitesLimitsDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites_limits',
  });

  const { t: tCommon } = useTranslation();

  const [initialValues, setInitialValues] = useState({
    payinMinOrderAmount: 0,
    payinMaxOrderAmount: 0,
  });

  const validationSchema = Yup.object().shape({
    payinMinOrderAmount: Yup.number().required(tCommon('errors.required')),
    payinMaxOrderAmount: Yup.number().required(tCommon('errors.required')),
  });

  const queryResult = useQuery(QueryKey.MyInfo, tradersApi.getMyInfo, {
    enabled: open,
    onSuccess: (data) => {
      setInitialValues({
        payinMinOrderAmount: data.payinMinOrderAmount || 0,
        payinMaxOrderAmount: data.payinMaxOrderAmount || 0,
      });
    },
  });

  const updateTraderMutation = useMutation(tradersApi.update);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          onClose({ ok: true });
          formikHelpers.resetForm();
          queryResult.remove();
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      updateTraderMutation.mutate({ data: values }, options);
    },
    [updateTraderMutation, onClose, queryResult],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        queryResult.remove();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose, queryResult],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('title')}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={updateTraderMutation}
          okDisabled={
            !formik.isValid || queryResult.isLoading || queryResult.isRefetching
          }
          onClose={handleClose}
        >
          <DataWrapper queryResult={queryResult}>
            <FormControls>
              <FormikNumericField
                fullWidth
                name="payinMinOrderAmount"
                label={t('fields.payinMinOrderAmount')}
                decimalScale={0}
                allowNegative={false}
              />
              <FormikNumericField
                fullWidth
                name="payinMaxOrderAmount"
                label={t('fields.payinMaxOrderAmount')}
                decimalScale={0}
                allowNegative={false}
              />
            </FormControls>
          </DataWrapper>
        </Dialog>
      )}
    </Formik>
  );
};
